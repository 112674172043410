
const Disclaimer = () => {
  return (
    <div className='relative w-full h-full text-black'>
      <div 
        className='fixed bottom-0 lg:text-[14px] text-[8px] w-full max-w-[1378px] text-center border p-4 bg-[#BADBDA] border-t-2 border-t-[#FC4029]'
      >
        This app helps you understand the emotional state of your conversation partner and provides psychological tips. We capture non-verbal signals and make assumptions based on them. Think of our product as a tool and entertainment, but the final decision is always yours and depends on your empathy. <br/> Enjoy using it!
      </div>
    </div>
  )
}

export default Disclaimer;

// import { ExclamationMark } from './ui/icons'

// const Disclaimer = () => {
//   return (
//       <div className='w-full h-full lg:text-[18px] text-[12px] text-center px-2' >
//         <p>This app helps you understand the emotional state of your conversation partner and provides psychological tips. We capture non-verbal signals and make assumptions based on them. Think of our product as a tool and entertainment, but the final decision is always yours and depends on your empathy. <br/> Enjoy using it!</p>
//       </div>

//   )
// }

// export default Disclaimer;

